import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import "./theme/style.scss";

import reportWebVitals from "./reportWebVitals";

import { client } from "./client";
import { load } from "./components/PageLoader";

Sentry.init({ dsn: "https://90e71e0877bd4208ba94eacfeaf7038f@sentry.io/1430675", environment: process.env.NODE_ENV });

const Admin = load(() => import("./views/admin"));
const POS = load(() => import("./views/pos"));
const Referee = load(() => import("./views/referee"));
const Screen = load(() => import("./views/screen"));
const Teletext = load(() => import("./views/teletext"));
const App = load(() => import("./views/app"));
const Login = load(() => import("./views/Login"));

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Router>
				<Routes>
					<Route path="admin/*" element={<Admin />} />
					<Route path="pos/*" element={<POS />} />
					<Route path="referee/*" element={<Referee />} />
					<Route path="screen/*" element={<Screen />} />
					<Route path="txt/*" element={<Teletext />} />
					<Route path="login" element={<Login />} />
					<Route path="/*" element={<App />} />
				</Routes>
			</Router>
		</ApolloProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import Loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { Col, Spinner } from "react-bootstrap";

import ErrorMessage from "./ErrorMessage";

export const load = <T,>(func: () => Promise<DefaultComponent<T>>) => {
	return Loadable(func, { fallback: <Spinner /> });
};

export interface PageLoaderProps {
	error?: any;
	loading?: boolean;
}

export const PageLoader: React.FC<PageLoaderProps> = ({ error, loading }) => {
	if (error) {
		return <ErrorMessage error={error} />;
	}

	if (loading) {
		return (
			<Col>
				<Spinner />
			</Col>
		);
	}

	return null;
};

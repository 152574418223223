import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const port = window.location.port === '3000' ? ':2000' : '';

const link = new HttpLink({
	uri: `//${window.location.hostname}${port}/graphql`,
	credentials: 'include'
});

const cache = new InMemoryCache();

export const client = new ApolloClient({ cache, link });

import { ApolloError, ServerError, ServerParseError } from "@apollo/client";
import { FC, useCallback, useRef } from "react";
import { Collapse } from "bootstrap";
import { Alert, Button } from "react-bootstrap";

export interface ErrorMessageProps {
	error: ApolloError;
}

function isServerError(error: Error): error is ServerError {
	return error.name === "ServerError";
}
function isServerParseError(error: Error): error is ServerParseError {
	return error.name === "ServerParseError";
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
	const ref = useRef<HTMLDivElement>(null);

	const handleToggleRaw = useCallback(() => {
		if (!ref.current) {
			return;
		}

		const collapse = new Collapse(ref.current);
		collapse.toggle();
	}, []);

	return (
		<Alert variant="danger" className="p-3">
			<Alert.Heading>Error</Alert.Heading>

			<p className="mb-3">{error.message}</p>

			{error.clientErrors.length > 0 && (
				<>
					<p className="mb-3">Client Errors!</p>
					<pre>{JSON.stringify(error.clientErrors)}</pre>
				</>
			)}

			{error.graphQLErrors.length > 0 && (
				<>
					<p className="mb-3">GraphQL Errors!</p>
					<ul>
						{error.graphQLErrors.map((err, i) => (
							<li key={i}>
								{err.message}: {err.extensions?.code as string} (@ {err.path?.join(".")})<br />
								<ul>
									{(err.extensions?.response as any)?.message?.map((m: string, j: number) => (
										<li key={j}>{m}</li>
									))}
								</ul>
							</li>
						))}
					</ul>
				</>
			)}

			{error.networkError && (
				<>
					<p className="mb-3">Network Error!</p>
					{isServerError(error.networkError) ? (
						<ul>
							{error.networkError.result?.errors?.map((e: Error, i: number) => (
								<li key={i}>{e.message}</li>
							))}
						</ul>
					) : isServerParseError(error.networkError) ? (
						<p>{error.networkError.bodyText}</p>
					) : (
						JSON.stringify(error.networkError)
					)}
				</>
			)}

			<Button onClick={handleToggleRaw}>Show raw</Button>

			<div ref={ref} className="collapse">
				<pre>{JSON.stringify(error, null, 2)}</pre>
			</div>
		</Alert>
	);
};

export default ErrorMessage;
